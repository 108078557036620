import React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/layout"
import Next from "../../components/next"
import Tooltip from "../../components/tooltip"

const Page = ({data}) => (
  <Layout title="Smartix" keywords={[`rail api`, `train ticket`, `api`]} submenu={data.allRailapisubmenuJson.edges}>
    <content>
      <h1>Settlement (SDCI+)</h1>

      <p>Settlement is the process of reporting sales to <Tooltip>RDG</Tooltip>. <Tooltip>SDCI+</Tooltip> files are sent to <Tooltip>Lennon</Tooltip> automatically 
      at regular intervals throughout the day.</p>

      <p>Whilst the mechanics might not be of interest, what matters to you as a licensed retailer*, is that you take the customer's money and you 
      pay <Tooltip>RDG</Tooltip> directly for those tickets. We are not involved in payments or commissions. All money remains in your bank until you settle 
      with <Tooltip>RDG</Tooltip>.</p>


      <Next submenu={data.allRailapisubmenuJson.edges}/>

      <p class="subnote">* If you are not a licensed retailer and that is not the best option for you, we can introduce you to a license holder under who's license you may operate.</p>

    </content>
  </Layout>
)

export const query = graphql`
  query {
    allRailapisubmenuJson {
      edges {
        node {
          title
          path
        }
      }
    }
  } 
`

export default Page